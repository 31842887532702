<template>
  <a-modal
    title="Zapytaj lekarza"
    class="add-message"
    v-model:visible="visible"
    :afterClose="afterClose"
  >
    <p>
      Chcesz zadać lekarzowi pytanie po odbytej wizycie? Możesz to zrobić na
      naszym portalu do 24h od zakończenia wizyty.
    </p>

    <a-form ref="refForm" :model="formModel" :rules="formRules">
      <a-form-item name="message">
        <a-textarea
          class="add-message__textarea"
          placeholder="Wpisz swoje pytanie..."
          autocomplete="off"
          v-model:value="formModel.message"
        />
      </a-form-item>
    </a-form>

    <template v-slot:footer>
      <a-button
        key="submit"
        type="primary"
        :loading="confirmLoading"
        :disabled="!formModel.message"
        @click="addMessage"
      >
        Wyślij
      </a-button>
      <a-button key="back" @click="handleCancel">
        Anuluj
      </a-button>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: "AddMessage",
  components: {},
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    visitId: {
      type: String,
      required: true,
    },
  },
  emits: ["after-confirm-success", "update:modelValue"],
  data() {
    return {
      confirmLoading: false,
      formModel: {
        message: "",
      },
      formRules: {
        message: [
          {
            required: true,
            message: "Pole jest wymagane",
          },
        ],
      },
      visible: false,
    };
  },
  watch: {
    modelValue(nv) {
      this.visible = nv;
    },
  },
  methods: {
    addMessage() {
      this.confirmLoading = true;

      this.$http
        .post(`patient-portal/api/conversation/message/${this.visitId}`, { message: this.formModel.message })
        .then(() => {
          this.visible = false;
          this.confirmLoading = false;
          this.$notification.open({
            message: "Wiadomość została wysłana pomyślnie",
            class: "success",
          });
          this.$emit("after-confirm-success");
        })
        .catch((error) => {
          this.$notification.open({
            message: error.response.data.errorMessage,
            class: "error",
          });
        });
    },
    handleCancel() {
      this.visible = false;
    },
    afterClose() {
      this.formModel.message = "";
      this.$emit("update:modelValue", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-message {
  .ant-form-item {
    margin-bottom: 0;
  }
  .add-message__textarea {
    height: 120px;
    resize: none;
  }
}
</style>
