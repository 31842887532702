<template>
  <div class="chat">
    <a-skeleton active :paragraph="false" :loading="!data">
      <a-breadcrumb>
        <a-breadcrumb-item>
          <router-link to="/">Moje wizyty</router-link></a-breadcrumb-item
        >
        <a-breadcrumb-item>
          <router-link :to="'/visit/' + this.id">
            Wizyta z dnia {{ data.parsedAppointmentAt.slice(0, 10) }} ({{
              data.doctor
            }})
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>Rozmowa z lekarzem</a-breadcrumb-item>
      </a-breadcrumb>
    </a-skeleton>
    <a-skeleton
      class="chat__skeleton-info"
      active
      :paragraph="false"
      :loading="!data || !messages"
    >
      <a-alert class="chat__info" type="info" show-icon>
        <template v-slot:icon><InfoCircleOutlined /></template>
        <template v-slot:description>
          <span v-show="data.patientAnswered">
            Możliwość zadania pytania została zablokowana
          </span>
          <span v-show="!data.isExpired && !data.patientAnswered">
            Możliwość rozmowy z lekarzem zostanie zablokowana
            {{ data.parsedConversationExpiresAt.slice(0, 10) }} o
            {{ data.parsedConversationExpiresAt.slice(11, 16) }}.
          </span>
          <span v-show="data.isExpired">
            Możliwość rozmowy z lekarzem została zablokowana
            {{ data.parsedConversationExpiresAt.slice(0, 10) }} o
            {{ data.parsedConversationExpiresAt.slice(11, 16) }}. Możesz pobrać
            całą historię rozmowy w dokumentach z wizyty.
          </span>
        </template>
      </a-alert>
    </a-skeleton>
    <div class="chat__messages">
      <div class="chat__messages-header">
        <a-skeleton active :paragraph="false" :loading="!data || !messages">
          <span>Rozmowa z lekarzem</span>
          <div class="visit__table-header-action">
            <a-button
              :disabled="data.isExpired || data.patientAnswered"
              @click="addMessageVisible = true"
              type="link"
            >
              <template v-slot:icon><MessageOutlined /></template>Zadaj pytanie
            </a-button>
          </div>
        </a-skeleton>
      </div>
      <div class="chat__messages-body">
        <div v-if="!messages">
          <a-row
            v-for="i in [1, 2, 3]"
            :key="i"
            type="flex"
            :justify="i % 2 ? 'start' : 'end'"
          >
            <a-col
              :lg="{ span: 20 }"
              :md="{ span: 20 }"
              :sm="{ span: 22 }"
              :xs="{ span: 24 }"
            >
              <a-skeleton
                class="chat__skeleton-message"
                active
                :paragraph="false"
                :loading="!data"
              />
            </a-col>
          </a-row>
        </div>
        <div v-if="messages">
          <a-row
            v-for="msg in messages"
            :key="msg.id"
            :justify="store.user.roles.includes(msg.role) ? 'start' : 'end'"
            type="flex"
          >
            <a-col
              :lg="{ span: 20 }"
              :md="{ span: 20 }"
              :sm="{ span: 22 }"
              :xs="{ span: 24 }"
            >
              <div
                class="chat__message"
                :class="{
                  'chat__message--own': store.user.roles.includes(msg.role),
                }"
              >
                <div class="chat__author">
                  {{ msg.senderName }} ({{ senderNameTypeMap[msg.role] }})
                </div>
                <div class="chat__date">
                  {{ msg.parseDeliveredOn }}
                </div>
                <div class="chat__text">
                  {{ msg.message }}
                </div>
              </div></a-col
            >
          </a-row>
        </div>
      </div>
    </div>
    <add-message
      v-model="addMessageVisible"
      :visitId="id"
      @after-confirm-success="afterAddMessageModalSuccess"
    />
  </div>
</template>

<script>
import { parseDate } from "@/utils";
import { InfoCircleOutlined, MessageOutlined } from "@ant-design/icons-vue";
import AddMessage from "@/components/AddMessage";
import { store } from "@/store";

export default {
  name: "Chat",
  props: ["id"],
  components: {
    InfoCircleOutlined,
    MessageOutlined,
    AddMessage,
  },
  mounted() {
    this.$http.put(
      `patient-portal/api/notification/deactivate?type=MESSAGE&appointmentId=${this.id}`
    );
    this.$http
      .get(`patient-portal/api/appointments/${this.id}`)
      .then((res) => {
        this.data = res.data;
        this.data.parsedAppointmentAt = parseDate(this.data.appointmentAt);
        this.data.parsedConversationExpiresAt = parseDate(
          this.data.conversationExpiresAt
        );
        this.data.isExpired =
          new Date() > new Date(this.data.conversationExpiresAt);
        this.getMessages();
      })
      .catch(() => {
        this.$notification.open({
          message: "Wystąpił błąd podczas wczytywania szczegółów wizyty",
          class: "error",
        });
      });
  },
  data() {
    return {
      messages: null,
      data: null,
      addMessageVisible: false,
      senderNameTypeMap: {
        DOCTOR: "Doktor",
        PATIENT: "Pacjent",
      },
      store,
    };
  },
  methods: {
    getMessages() {
      this.$http
        .get(`patient-portal/api/conversation/${this.id}`)
        .then((res) => {
          let tmpMessages = [];
          res.data.forEach((msg) => {
            if (this.store.user.roles.includes(msg.role)) {
              this.data.patientAnswered = true;
            }
            msg.parseDeliveredOn = parseDate(msg.deliveredOn);
            tmpMessages.push(msg);
          });
          this.messages = tmpMessages;
        })
        .catch(() => {
          this.$notification.open({
            message: "Wystąpił błąd podczas wczytywania wiadomości",
            class: "error",
          });
        });
    },
    afterAddMessageModalSuccess() {
      this.getMessages();
    },
  },
};
</script>

<style lang="scss" scoped>
.chat {
  padding: 24px 10px;
  max-width: $container-width;
  margin: 0 auto;

  h1 {
    margin-top: 32px;
    font-size: 20px;
    font-weight: bold;
  }

  .chat__skeleton-info {
    margin-top: 34px;
    ::v-deep(.ant-skeleton-content .ant-skeleton-title) {
      height: 74px;
    }
  }
  .chat__messages {
    .chat__messages-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 30px;
      > span {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .chat__messages-body {
      margin-top: 24px;

      .chat__message {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        background-color: $c4;
        padding: 16px;
        margin-bottom: 24px;

        &.chat__message--own {
          background-color: $g3;
        }

        .chat__author {
          font-weight: bold;
          flex: 0 1 auto;
        }
        .chat__date {
          flex: 0 1 auto;
        }
        .chat__text {
          margin-top: 10px;
          flex: 1 0 100%;
        }
      }

      .chat__skeleton-message {
        margin-bottom: 24px;
        &:nth-child(2n) {
          ::v-deep(.ant-skeleton-content .ant-skeleton-title) {
            float: right;
          }
        }
        ::v-deep(.ant-skeleton-content .ant-skeleton-title) {
          height: 125px;
        }
      }
    }
  }

  .chat__info {
    margin-top: 18px;
    .ant-btn {
      float: right;
      margin-top: -6px;
      @media (max-width: 768px) {
        margin-top: 12px;
      }
    }
  }
}
</style>
